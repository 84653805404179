import { ActionReducerMap, createSelector } from '@ngrx/store';
import * as fromLayout from '../layout/store/reducers/layout.reducer';

export interface AppRootState {
  layout: fromLayout.LayoutState;
}

/**
 * @ignore
 */
export const rootReducer: ActionReducerMap<AppRootState, any> = {
  layout: fromLayout.reducer,
}

/**
 * Select layout state
 * @param {AppRootState} state
 */
export const selectLayoutState = (state: AppRootState) => state.layout;

/**
 * Getting whether navbar should be displayed from layout reducer
 */
export const getNavbarDisplayedState = createSelector(
  selectLayoutState, fromLayout.navbarDisplayed
);
