import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { SnackBarSettings } from '../utils/types/snack-bar-settings.type';

/**
 * Default Snack bar settings
 * @type { SnackBarSettings }
 */
const defaultSnackBarSettings: SnackBarSettings = {
  message: '',
  action: true,
  actionButtonLabel: 'Close',
  autoHide: true,
  autoHideAfter: 10000,
  horizontalPosition: 'center',
  verticalPosition: 'bottom',
  extraClasses: []
};

/**
 * Notification service class
 * Implements Material design Snack bar
 */
@Injectable({ providedIn: 'root' })
export class NotificationService {

  /**
   * Snack Bar settings
   * @type { SnackBarSettings }
   */
  private snackBarSettings: SnackBarSettings;

  /**
   * Notification service constructor
   * @param { MatSnackBar } snackBar Material design Snack bar
   */
  constructor(public snackBar: MatSnackBar) {}

  /**
   * Information Snack bar
   * @param { string } message
   */
  public showInfo(message: string) {
    this.snackBarSettings = {
      ...defaultSnackBarSettings,
      message: message,
      autoHideAfter: 2000,
      extraClasses: ['info-snackbar']
    };
    this.openSnackBar();
  }

  /**
   * Success Snack bar
   * @param { string } message
   */
  public showSuccess(message: string) {
    this.snackBarSettings = {
      ...defaultSnackBarSettings,
      message: message,
      autoHideAfter: 2000,
      horizontalPosition: 'left',
      extraClasses: ['success-snackbar']
    };
    this.openSnackBar();
  }

  /**
   * Error Snack bar
   * @param { string } message
   */
  public showError(message: string) {
    this.snackBarSettings = {
      ...defaultSnackBarSettings,
      message: message,
      horizontalPosition: 'right',
      extraClasses: ['error-snackbar']
    };
    this.openSnackBar();
  }

  /**
   * Set configuration and open Snack bar
   */
  private openSnackBar() {
    const config = new MatSnackBarConfig();
    config.duration = this.snackBarSettings.autoHide ? this.snackBarSettings.autoHideAfter : 0;
    config.verticalPosition = this.snackBarSettings.verticalPosition;
    config.horizontalPosition = this.snackBarSettings.horizontalPosition;
    config.panelClass = this.snackBarSettings.extraClasses;

    this.snackBar.open(
      this.snackBarSettings.message,
      this.snackBarSettings.action ? this.snackBarSettings.actionButtonLabel : undefined,
      config);
  }
}
