import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard } from './core/guards/authentication.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./users/pages/login/login.module')
      .then(m => m.LoginModule),
    data: {
      pageTitle: 'Admin | Login',
    },
  },
  {
    path: 'orders',
    loadChildren: () => import('./orders/orders.module')
      .then(m => m.OrdersModule),
    canActivate: [AuthenticationGuard],
    data: {
      pageTitle: 'Admin | Orders',
    },
  },
  {
    path: 'products',
    loadChildren: () => import('./products/products.module')
      .then(m => m.ProductsModule),
    canActivate: [AuthenticationGuard],
    data: {
      pageTitle: 'Admin | Products',
    },
  },
  {
    path: 'product-categories',
    loadChildren: () => import('./product-categories/product-categories.module')
      .then(m => m.ProductCategoriesModule),
    canActivate: [AuthenticationGuard],
    data: {
      pageTitle: 'Admin | Product Categories',
    },
  },
  {
    path: 'product-subcategories',
    loadChildren: () => import('./product-subcategories/product-subcategories.module')
      .then(m => m.ProductSubcategoriesModule),
    canActivate: [AuthenticationGuard],
    data: {
      pageTitle: 'Admin | Product Subcategories',
    },
  },
  {
    path: 'not-found',
    loadChildren: () => import('./shared/pages/not-found/not-found.module')
      .then(m => m.NotFoundModule),
    data: {
      pageTitle: 'Admin | Not found',
    },
  },
  {
    path: '**',
    redirectTo: 'not-found'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [ RouterModule ]
})
export class AppRoutingModule { }
