import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from './users/services/auth.service';
import { MatSidenav } from '@angular/material/sidenav';
import { Observable } from 'rxjs';
import * as fromAppRoot from './core/store';
import { select, State } from '@ngrx/store';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'admin-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  public opened: boolean = false;

  public navbarDisplayed$: Observable<boolean>;

  @ViewChild('sidenav', { static: true }) sidenav: MatSidenav;

  constructor(
    private authService: AuthService,
    private store$: State<fromAppRoot.AppRootState>,
    private router$: Router,
    private activatedRoute$: ActivatedRoute,
    private titleService: Title,
  ) {

    this.navbarDisplayed$ = store$.pipe(select(fromAppRoot.getNavbarDisplayedState));
  }

  ngOnInit() {
    this.router$.events
      .pipe(
        map((event: RouterEvent) => event),
        filter((event: RouterEvent) => event instanceof NavigationEnd),
        map(() => this.activatedRoute$),
        map((route: ActivatedRoute) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route: ActivatedRoute) => route.outlet === 'primary'),
        mergeMap((route: ActivatedRoute) => route.data)
      )
      .subscribe((event: { pageTitle: string }) => {
        this.titleService.setTitle(event.pageTitle);
      });
  }

  /**
   * Method for handling logging out the user
   */
  public logout() {
    this.authService.logout().subscribe(() => {
      localStorage.removeItem('userData')
      this.router$.navigate(['/'])
    });
  }
}
