import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../../users/services/auth.service';
import { map, take } from 'rxjs/operators';
import { UserInfo } from '../../users/types/user-info.type';

/**
 * Authentication guard resolver
 * Route URL can be activated only if user is logged in
 */
@Injectable({ providedIn: 'root' })
export class AuthenticationGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    router: RouterStateSnapshot
  ): boolean | UrlTree | Promise<boolean | UrlTree> | Observable<boolean | UrlTree> {
    return this.authService.autoLogin().pipe(
      take(1),
      map((user: UserInfo) => {
        const isAuth = !!user
        if (isAuth) {
          return true
        }
        return this.router.createUrlTree(['/'])
      }),
    )
  }
}
