import { Component, Input } from '@angular/core';
import { LoaderService } from './loader.service';
import { Observable, Subject } from 'rxjs';

/**
 *
 *
 * @export
 * @class AppLoaderComponent
 */
@Component({
  selector: 'admin-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent {
  @Input()
  public message: string;

  public isLoading: Subject<boolean> = this.loaderService.isAppLoading;

  public isLoading$: Observable<boolean> = this.isLoading.asObservable();

  /**
   * Creates an instance of LoaderComponent.
   * @param {LoaderService} loaderService
   * @memberof LoaderComponent
   */
  public constructor(private loaderService: LoaderService) { }

}
