import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { catchError, map, switchMap, tap } from "rxjs/operators";
import { BehaviorSubject, Observable, of, Subject, throwError } from "rxjs";
import { User } from '../models/user.model';
import { UserInfo } from '../types/user-info.type';

/**
 * Authentication service
 */
@Injectable({ providedIn: 'root' })
export class AuthService {

  private readonly BASE_URL: string = '/auth';

  /**
   * Observable of user info
   * @type {Observable<User>}
   */
  public user$: Subject<User> = new BehaviorSubject<User>(<User>{});

  constructor(private http: HttpClient) {}

  /**
   * Method for logging in admin user
   * @param {string} email
   * @param {string} password
   */
  public login(email: string, password: string) {
    return this.http.post<UserInfo>(`${this.BASE_URL}/login`, {
      email: email,
      password: password,
    }, { withCredentials: true }).pipe(
      tap((userInfo: UserInfo) => this.handleAuthentication(userInfo)),
      catchError(AuthService.handleError)
    )
  }

  /**
   * Method for auto logging in user
   */
  public autoLogin(): Observable<UserInfo> {
    return this.http.post<UserInfo>(`${this.BASE_URL}/refresh`, {}, {
      withCredentials: true,
    }).pipe(
      switchMap((userInfo: UserInfo) => this.handleAuthentication(userInfo)),
    )
  }

  /**
   * Method for registering admin user
   * @param {string} email
   * @param {string} password
   */
  public signup(email: string, password: string) {
    return this.http.post<UserInfo>(`${this.BASE_URL}/signup`, {
      email: email,
      password: password,
    }).pipe(
      tap((userInfo: UserInfo) => this.handleAuthentication(userInfo)),
      catchError(AuthService.handleError)
    )
  }

  /**
   * Method for handling authentication response
   * @param {UserInfo} userInfo
   * @return UserInfo
   * @private
   */
  private handleAuthentication(userInfo: UserInfo): Observable<UserInfo> {
    const user = new User(
      userInfo.payload.id,
      userInfo.payload.email,
      userInfo.accessToken,
    )
    this.user$.next(user)
    localStorage.setItem('userData', JSON.stringify(user))
    return of(userInfo);
  }


  /**
   * Method for logging out user
   */
  public logout(): Observable<string | boolean> {
    return this.http.post<HttpResponse<any>>(`${this.BASE_URL}/logout`, {}).pipe(
      map(() => {
        this.user$.next(<User>{});
        return true;
      }),
      catchError(AuthService.handleError)
    )
  }

  /**
   * Method for handling authentication error
   * @param {HttpErrorResponse} errorRes
   * @return {Observable<string>}
   * @private
   */
  private static handleError(errorRes: HttpErrorResponse): Observable<string> {
    return errorRes.error && errorRes.error.message
      ? throwError(errorRes.error.message)
      : throwError('An unknown error occurred.')
  }
}
