import { HttpErrorResponse } from '@angular/common/http';

/**
 * General Application Http Error class
 */
export class AppHttpError {

  /**
   * Dependency injection for Original Error message
   * @param { HttpErrorResponse } originalError HTTP error response original error
   */
  constructor(public originalError: HttpErrorResponse) {}
}
