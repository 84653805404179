<div class="app-green-theme" id="appRootClass">
  <!-- tool bar - start -->
  <mat-toolbar *ngIf="navbarDisplayed$ | async" color="warn">
    <mat-toolbar-row class="toolbar">
      <div class="navbar-left">
        <button mat-icon-button (click)="sidenav.toggle()">
          <mat-icon>{{ opened ? 'menu_open' : 'menu' }}</mat-icon>
        </button>
        <span>Rasveta-elektro Admin Panel</span>
      </div>
      <div class="navbar-right"></div>
    </mat-toolbar-row>
  </mat-toolbar>
  <!-- tool bar - end -->

  <mat-sidenav-container>
    <!-- side nav  - start -->
    <mat-sidenav #sidenav>
      <mat-nav-list class="sidebar">
        <a routerLink="/orders" (click)="sidenav.toggle()" mat-list-item>Narudžbine</a>
        <a routerLink="/products" (click)="sidenav.toggle()" mat-list-item>Proizvodi</a>
        <a routerLink="/product-categories" (click)="sidenav.toggle()" mat-list-item>Kategorije</a>
        <a routerLink="/product-subcategories" (click)="sidenav.toggle()" mat-list-item>Potkategorije</a>
        <button (click)="logout(); sidenav.toggle()" mat-list-item>Izloguj se</button>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content class="bg-gray-200 pb-12">
      <main>
        <router-outlet></router-outlet>
      </main>
    </mat-sidenav-content>
    <!-- side nav  - ends -->
  </mat-sidenav-container>
</div>

<admin-loader></admin-loader>
