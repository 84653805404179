import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest, HttpStatusCode
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { UserInfo } from '../../users/types/user-info.type';
import { AuthService } from '../../users/services/auth.service';

/**
 * Authentication interceptor service
 * Setting Authorization header with user's token
 */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private accessToken: string = '';
  private refresh: boolean = false;

  constructor(private authService: AuthService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const req = request.clone({
      setHeaders: {
        Authorization: `Bearer ${this.accessToken}`
      }
    });

    return next.handle(req).pipe(catchError((err: HttpErrorResponse) => {
      if (err.status === HttpStatusCode.Unauthorized && !this.refresh) {
        this.refresh = true;

        return this.authService.autoLogin().pipe(
          switchMap((userInfo: UserInfo) => {
            this.accessToken = userInfo.accessToken;

            return next.handle(request.clone({
              setHeaders: {
                Authorization: `Bearer ${this.accessToken}`
              }
            }));
          }),
        );
      }
      this.refresh = false;
      return throwError(err)
    }))
  }
}
