import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular';
import { environment } from '../../../environments/environment';
import { Environments } from '../utils/enums/environments.enum';

/**
 * Logger service
 */
@Injectable({ providedIn: 'root' })
export class LoggerService {

  /**
   * Log logger. If environment isn't production, then throw log in browser console
   * @param value
   * @param rest
   */
  log(value: any, ...rest: any[]) {
    if (environment.name !== Environments.PRODUCTION) {
      console.log(value, ...rest);
    }
  }

  /**
   * Error logger. If environment is production, staging, or test/dev then send error on Sentry.
   * If environment is staging, test/dev, or local then throw error in browser console
   * @param {Error | string} error
   */
  error(error: Error | string) {
    // if (environment.production) {
      Sentry.captureException(error);
    // }

    if (environment.name !== Environments.PRODUCTION) {
      console.error(error);
    }
  }

  /**
   * Warning logger. If environment isn't production, then throw warn in browser console
   * @param value
   * @param rest
   */
  warn(value: any, ...rest: any[]) {
    if (environment.name !== Environments.PRODUCTION) {
      console.warn(value, ...rest);
    }
  }
}
