import { Environments } from "../app/core/utils/enums/environments.enum";

export const environment = {
  production: true,
  name: Environments.PRODUCTION,
  url: '/api',
  /**
   * Sentry DSN configuration for environment
   */
  sentry: {
    dsn: 'https://e5ceb0fea2104179ac22de8b79223775@o1413356.ingest.sentry.io/6760852',
    sampleRate: 1.0,
  },
};
