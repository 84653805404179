import { LayoutActionsTypes, NavbarActions } from '../actions';

export interface LayoutState {
  /**
   * State whether the navbar should be displayed
   * @type boolean
   */
  navbarDisplayed: boolean;
}

/**
 * @ignore
 */
export const initialState: LayoutState = {
  navbarDisplayed: true,
}

/**
 * Layout reducer function
 * @param {LayoutState} state
 * @param {LayoutActionsTypes} action
 */
export function reducer(state: LayoutState = initialState, action: LayoutActionsTypes) {
  switch (action.type) {

    case NavbarActions.toggle.type: {
      return {
        ...state,
        navbarDisplayed: action.display,
      }
    }

    default:
      return state;
  }
}

/**
 * State whether navbar should be displayed from layout reducer
 * @param {LayoutState} state
 * @return boolean
 */
export const navbarDisplayed = (state: LayoutState): boolean => state.navbarDisplayed;
