/**
 * User model
 */
export class User {
  constructor(
    public id: number,
    public email: string,
    private accessToken: string,
  ) {}

  get token() {
    return this.accessToken
  }
}
